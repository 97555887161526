// 此时我们还没有微应用，所以 apps 为空
import store from "../store";
var isDev = process.env.NODE_ENV==='development';
import apps_pro from "./app-config";
import apps_dev from "./app-config-dev";
var microApps = isDev?apps_dev:apps_pro;
const apps = microApps.map(item => {
    return {
        ...item,
        container: item.container, // 子应用挂载的div
        props: {
            // routerBase: item.activeRule, // 下发基础路由
            getGlobalState: store.getGlobalState // 下发getGlobalState方法
        },
    }
})
export default apps;
