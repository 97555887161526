import Vue from 'vue'
import App from './App.vue'
import router from './router'
import startQiankun from "./micro";
import axios from './plugins/axios'
import "../static/css/main.css";
Vue.prototype.$axios = axios
import './plugins/element.js'
import {url} from '../static/js/url.js'
import {constant} from "../static/js/constant";

Vue.prototype.$constant = constant
Vue.prototype.$URL = url
Vue.config.productionTip = false
window.direct = process.env.VUE_APP_Redirect
console.log('direct',window.direct)
var environment =  process.env.NODE_ENV
var dTick = Vue.prototype.$constant.refreshCheckTick
if(environment!='production'){
    dTick = Vue.prototype.$constant.refreshCheckTick_test
}
console.log('刷新间隔',dTick)
router.beforeEach((to, from, next) => {
    console.log('钩子函数的路由',to)
    console.log('form',from);
    var token = to.query.token
    var tokenType = to.query.tokenType
    console.log('token:',token)
    console.log('tokenType:',tokenType)
    if(!token||!tokenType){
        var storageToken = sessionStorage.getItem('essist_portal_hub_token')
        var storageTokenType = sessionStorage.getItem('essist_portal_hub_tokenType')
        if(!storageToken||!storageTokenType) {
            var url = Vue.prototype.$URL.loginUrl + '?state=' + window.direct
            console.log('用户未登录', url)
            window.location.href = url
        }else{
            console.log('用户刷新')
            var currentTick = new Date().getTime()
            var loginTickS = sessionStorage.getItem('loginTick')
            if(currentTick-Number(loginTickS)>=dTick){
                console.log('该重新登陆了亲！')
                sessionStorage.removeItem('essist_portal_hub_token')
                sessionStorage.removeItem('essist_portal_hub_tokenType')
                // window.location.href = Vue.prototype.$URL.logoutUrl+'?state='+window.direct
            }else{
                next()
            }
            return
        }
    }else{
        console.log('用户重新登陆')
        var loginTick = new Date().getTime().toString()
        sessionStorage.setItem('loginTick',loginTick)
        sessionStorage.setItem('essist_portal_hub_token',token||'')
        sessionStorage.setItem('essist_portal_hub_tokenType',tokenType||'')
    }
    if(to.path=='/'&&to.query.token){
        window.location.href=window.direct
    }else{
        next()
    }

})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// startQiankun();


