const url={
    /**获取menu菜单接口**/
    menuUrl:'https://api.eassist.me/hub/menu',
    /**转到登陆*/
    loginUrl:'https://sso.eassist.me/Login',
    /**转到登出**/
    logoutUrl:'https://sso.eassist.me/logout',
    /**获取用户信息**/
    userInfoUrl:'https://sso.eassist.me/api/oauth2/userInfo',
    /**首页地址*/
    dashboardUrl:'https://frame-hub2.eassist.me/Home/Dashboard',
    /**项目打包url*/
    localUrl: process.env.VUE_APP_Redirect,
}
export {url}