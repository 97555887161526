const apps_dev = [
    /**
     * name: 微应用名称 - 具有唯一性
     * entry: 微应用入口 - 通过该地址加载微应用
     * container: 微应用挂载节点 - 微应用加载完成后将挂载在该节点上
     * activeRule: 微应用触发的路由规则 - 触发路由规则后将加载该微应用
     */
    {
        name:'itAdmin',
        entry:'https://test.aiclick.net/apps/itAdmin/',
        container: "#frame",
        activeRule:'/sub/itAdmin',
    },{
        name:'invoice',
        entry:'https://test.aiclick.net/apps/invoice/',
        container: "#frame",
        activeRule:'/sub/invoice',
    },{
        name:'payroll',
        entry:'https://test.aiclick.net/apps/payroll/',
        container: "#frame",
        activeRule:'/sub/payroll',
    }
];
export default apps_dev;
