<template>
    <!--<div class="scroll-box" :class="{'sidebar-box-scr': collapse}">-->
    <div>
        <div class="scroll-box" v-show="showSidebar">
            <div class="sidebar" @click="collapse = true">
<!--                这里不启用路由方式-->
                <el-menu
                        ref="sidebar"
                        :default-active="onRoutes"
                        :collapse="false"
                        background-color="#343a40"
                        text-color="#DDDDDD"
                        active-text-color="#41CAC0"
                        unique-opened
                >
                    <el-input class="filterInput" v-model="filterText"
                              clearable @input="filterMenu" placeholder="Search">
                        <el-icon slot="append" class="el-icon-search"></el-icon>
                    </el-input>
                    <template v-for="item in roleItems">
                        <template v-if="item.children">
                            <!--一级菜单-->
                            <el-submenu class="firstSub" style="margin-left: 15px;text-align: left;margin-top: 5px" :index="item.id" :key="item.id" :title="item.name">
                                <template slot="title">
                                    <img class="mr5 ml3" :src="item.icon" alt>
                                    <span class="ml20 fs14 menuTitle" slot="title">{{ item.name }}</span>
                                </template>

                                <template v-for="subItem in item.children">
                                    <template v-if="subItem.children&&subItem.children.length>0">
                                        <!--二级菜单-->
                                        <el-submenu :index="subItem.id" :key="subItem.id" :title="subItem.name">
                                            <template slot="title">
                                                <span slot="title" class="fs14 menuTitle">{{ subItem.name }}</span>
                                            </template>
                                            <!--三级菜单-->
                                            <el-menu-item
                                                    v-for="(ssi,i) in subItem.children"
                                                    :key="i"
                                                    :index="ssi.id"
                                                    :title="ssi.name"
                                                    @click="clickMenu(ssi)"
                                            >
                                                <!--<i :class="ssi.icon"></i>-->
                                                <span slot="title" class="fs14 menuTitle">{{ ssi.name }}</span>
                                            </el-menu-item>
                                        </el-submenu>
                                    </template>
                                    <template v-else>
                                        <!--二级菜单-->
                                        <el-menu-item :index="subItem.id" :key="subItem.id" :title="subItem.name"
                                                      @click="clickMenu(subItem)">
                                            <!--<i :class="item.icon"></i>-->
                                            <span slot="title" class="fs14 menuTitle">{{ subItem.name }}</span>
                                        </el-menu-item>
                                    </template>
                                </template>
                            </el-submenu>
                        </template>
                        <template v-else>
                            <!--一级菜单-->
                            <el-menu-item class="firstSub" style="padding-left: 12px" :index="item.id" :key="item.id"
                                          :title="item.name">
                                <!--<i :class="item.icon"></i>-->
                                <img class="mr5 ml3" :src="item.icon" alt>
                                <span class="ml20 fs14 menuTitle" slot="title" >{{ item.name }}</span>
                            </el-menu-item>
                        </template>
                    </template>
                </el-menu>
            </div>
            <div class="scrollLine">

            </div>
        </div>
    </div>
</template>

<script>
    import bus from '../../plugins/bus.js';
    const rebuildData=(value, arr) => {
        let newarr = [];
        arr.forEach(element => {
            if (element.children && element.children.length) {
                const ab = rebuildData(value,element.children);
                const obj = {
                    ...element,
                    children: ab
                };
                if (ab && ab.length) {
                    newarr.push(obj);
                }else{
                    if(element.name.toLowerCase().indexOf(value.toLowerCase()) > -1){
                        element.children=[]
                        newarr.push(element);
                    }
                }
            } else {
                if (element.name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                    newarr.push(element);
                }
            }
        });
        return newarr;
    };

    export default {
        data() {
            return {
                showSidebar:true,
                filterText:'',
                hasUnReadNotice: false,
                unReadCount: "",
                collapse: true,
                sidebarWidth: 250,
                flag: false,
                zoom: false,
                roleItems:[],
                currentRoleItems:[],
                onRoutes:'',
            };
        },
        computed: {

        },
        created() {
            var url = location.pathname;
            bus.$on('changeScreen',flag=>{
                this.showSidebar = !flag
            })
            bus.$on("getMenuList", msg => {
                if(msg&&msg.email){
                    //此处线上需要去掉注释
                    var email = msg.email
                    // var email ='james.anderson@eassist.me'//测试菜单用的email
                    this.$parent.loading=true
                    this.$axios.get(this.$URL.menuUrl,{params:{email:email}}).then(res=>{
                        this.$parent.loading=false
                        // if(res.status==200){
                        this.roleItems=res
                        this.currentRoleItems = res
                        console.log('获取成功',res)
                        if(url&&url!='/'){
                            //如果地址栏有url，则说明是刷新
                            this.findNodeByUrl(url)
                        }
                        // }
                    }).catch(err=>{
                        console.log(err);
                        this.$parent.loading=false
                    })
                }
            });
        },
        mounted() {
            bus.$on("ChangeTab", msg => {
                this.onRoutes = msg.id
            });
            window.onresize = () => {
                let currentWidth = document.body.clientWidth
                let screenWidth = window.screen.width
                if (screenWidth / 2 > currentWidth) {
                    this.zoom = true
                    console.log(this.zoom);
                } else {
                    this.zoom = false
                }
            }

        },
        methods: {
            findNodeByUrl(url){
                var node = this.findNode(this.currentRoleItems,url,[])
                var onlyNode = node.filter(d=>{
                    return d.url == url
                })
                if(onlyNode&&onlyNode.length>0)this.clickMenu(onlyNode[0])
            },
            isHttp (s) {
                return (/^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/).test(s)
            },
            // 获取指定节点的路径数组
            filterMenu(){
                console.log('输入内容是？',this.filterText)
                this.roleItems = JSON.parse(JSON.stringify(this.currentRoleItems))
                var result = rebuildData(this.filterText,this.roleItems)
                this.roleItems = JSON.parse(JSON.stringify(result))
                console.log('结果',result)
            },
            clickMenu(node){
                var path = this.findIndexArray(this.currentRoleItems,node.id,[])
                console.log('路径是',path)
                node.path=path
               //用正则判断是否是一个网址
               var isUrl = this.isHttp(node.url)
               var isHttps = node.url.startsWith('https')
                if(!isUrl&&!isHttps){
                    if(node.url!=this.$route.path){
                        //如果匹配到当前规则则替换路由。此处先路由先写死
                        // var testUrl='/apps/itAdmin'
                        history.pushState(null, node.url, node.url)
                        // history.pushState(null, testUrl, testUrl)
                    }
                    //通知父控件显示路由页面
                    this.$emit('routeChange',true)
                }else{
                    //如果当前是iframe页面，则又切回基础路由
                    if(this.$route.path!='/') history.pushState(null, '/', '/')
                    //通知父控件隐藏路由页面
                    this.$emit('routeChange',false)
                }
                bus.$emit('Jump',node)
            },
            findIndexArray(data, id, indexArray) {
                let arr = Array.from(indexArray)
                for (let i = 0, len = data.length; i < len; i++) {
                    arr.push(data[i].name)
                    if (data[i].id === id) {
                        return arr
                    }
                    let children = data[i].children
                    if (children && children.length) {
                        let result = this.findIndexArray(children, id, arr)
                        if (result) return result
                    }
                    arr.pop()
                }
                return false
            },
            findNode(data, url, indexArray){
                 let arr = Array.from(indexArray)
                 for (let i = 0, len = data.length; i < len; i++) {
                    arr.push(data[i])
                    if (data[i].url === url) {
                        return arr
                    }
                    let children = data[i].children
                    if (children && children.length) {
                        let result = this.findNode(children, url, arr)
                        if (result) return result
                    }
                    arr.pop()
                }
                return false
            },
        },
        watch: {
            $route(to, from) {
                console.log('from', from.path);
                console.log('to', to.path);
                console.log('object', this.$route);
            },
        }
    };
</script>

<style scoped>
    .scroll-box {
        display: flex;
        position: absolute;
        left: 0;
        top: 90px;
        bottom: 0;
        overflow-x: hidden;
        -webkit-transition: width 0.2s ease-in-out;
        transition: width 0.2s ease-in-out;
        z-index: 100;
        width: 260px;
    }

    .sidebar {
        position: absolute;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-transition: width 0.2s ease-in-out;
        transition: width 0.2s ease-in-out;
        z-index: 10;
        width: 280px;
        height: 100%;
        background: #343a40;
    }

    /*.sidebar-scr{*/
    /*width: 250px;*/
    /*}*/
    .sidebar-box-scr {
        width: 200px;
    }

    .sidebar-el-menu:not(.el-menu--collapse) {
        width: 250px;
    }

    .sidebar > ul {
        height: 100%;
    }

    .handle-box {
        background-color: #fff;
        height: 100%;
        width: 250px;
        transform: translateX(-250px);
    }

    .btn-bell-badge {
        position: absolute;
        left: 15px;
        top: 25px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #f56c6c;
        color: #fff;
    }

    .red-text-color {
        color: red;
        position: absolute;
        margin-left: 30px;
        top: 2px;
    }

    .ml3 {
        margin-left: 3px;
    }
    .scroll-box .sidebar .el-submenu .el-submenu__title {
        padding-left: 12px !important;
    }
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 200px;
        min-height: 400px;
    }
    .filterInput{
        width: 220px;
        margin-top: 60px;
        height: 16px;
        font-size: 14px;
    }
    .filterInput>>>.el-input__inner{
        background: #374850!important;
        border: 1px solid #374850;
    }
   .filterInput>>>.el-input-group__append{
       background: #374850!important;
       border: 1px solid #374850;
    }
    .filterInput>>>.el-input-group__append:hover{
        color: #000;
    }
    .filterInput>>>.el-input__inner:focus{
        background: #fff!important;
        border: 1px solid #374850;
    }
    /*.sidebar>>>.el-submenu__title i{*/
    /*    margin-right: 15px;*/
    /*}*/
    .sidebar>>>ul:first-child{

    }
    .menuTitle{
        color:#c2c7d0 ;

        margin-left: -5px;
    }
    .menuTitle:hover{
        color: #fff;
    }
    .el-menu-item.is-active span{
        color: #fff !important;
    }
    .sidebar>>>.el-submenu__title{
        width: 220px;
        border-radius: 5px;
        height: 45px;
        line-height: 45px;
        margin-left: 5px;
        padding-left: -5px;
    }
    .sidebar>>>.el-submenu__title:hover{
        background-color: #494E53!important;
    }
    .sidebar>>>.el-menu-item{
        width: 220px;
        border-radius: 5px;
        height: 45px;
        margin-top: 5px;
        line-height: 45px;
        margin-left: 5px;
    }
    .sidebar>>>.el-menu-item:hover{
        background-color: #494E53!important;
    }
    @media screen and (max-width: 1200px) {
        .filterInput{
            margin-right: 18px
        }
    }

</style>
