<template>
    <div class="header" v-show="showHeader">
        <iframe :src="intervalUrl" frameborder="0" ref="loginReframe" style="height: 0px"></iframe>
        <div style="width:260px;height:20px;position:absolute;left:0px;
             display:inline-block;background: #343a40"></div>
        <div v-show="imgShow" class="logoBox">
            <img class="logoImg" ref="logoImg" :src="logo" alt="">
        </div>
        <div class="header-right">
                <!-- 用户头像 -->
<!--            <div style="background:red"><span>111</span>{{user}}</div>-->
            <div class="userInfo" slot="reference" @click="visible=true">
                <el-avatar style="box-shadow: 3px 3px 6px rgba(255, 193, 7, .6), 0 3px 6px rgba(255, 193, 7, .23) !important;" :src="avatar"></el-avatar>
                <span class="userName">{{userName}}</span>
            </div>
        </div>
        <el-dialog class="userDialog" width="22%"
                   :visible.sync="visible"
                    :modal="false">
            <el-image :src="avatar" style="width: 60px;margin-top: 20px"></el-image>
            <div class="fs22 mt20">{{userName}}</div>
            <div class="fs12">Member since</div>
            <div class="operate">
                <el-button @click="Profile" class="left">Profile</el-button>
                <el-button @click="loginOut" class="right">Sign out</el-button>
            </div>
        </el-dialog>
    </div>

</template>
<script>
    import bus from '../../plugins/bus.js';
    import logo from '../../../static/img/logo.png'
    import avatar from '../../../static/img/avatar.png'
    import {url} from "../../../static/js/url.js"
    import actions from '../../shared/actions'

    window.addEventListener('message', function(event) {
        // console.log(`Received ${event.data} from ${event.origin}`);
        console.log('Received Data',event,new Date());
        var status = event.data
        //此处线上需要去掉注释
        if(status=='Not Login'){
            sessionStorage.removeItem('essist_portal_hub_token')
            sessionStorage.removeItem('essist_portal_hub_tokenType')
            window.location.href=url.logoutUrl+'?state='+window.direct
            clearInterval(window.loginJudge)
            console.log('获取到登录状态为未登录，退出登录')
        }
    });

    export default {
        data() {
            return {
                intervalUrl:'https://sso.eassist.me/statusreport',
                userName:'',
                visible:false,
                avatar:avatar,
                logo:logo,
                versionsShow: false,
                collapse: true,
                fullscreen: false,
                name: 'linxin',
                changePasswordVisible:false,
                companyName: '',
                hasUnReadNotice: false,
                unReadCount: '0',
                imgShow: true,
                setWidth: true,
                showHeader:true,
            }
        },
        computed:{
            //从store里面获取公共数据
            // user () {
            //     return store.getGlobalState('user')
            // }
        },
        mounted () {
            this.getUserInfo()
            bus.$on('collapse', msg => {
                this.collapse = msg
            })
            bus.$on('changeScreen',flag=>{
                this.showHeader = !flag
                actions.setGlobalState({changeTableHeight:this.showHeader});
            })
            var d = this.$constant.checkLoginStatusTime
            window.loginJudge = setInterval(()=>{
                console.log('定时器走啊走,走满一分钟就退出！',d)
                this.loginMsg()
            },d)
            actions.onGlobalStateChange((state,preState)=>{
                //state:变更后的状态,preState:变更前的状态
                console.log('之前的数据',JSON.stringify(preState))
                console.log('检测到存值发生变化',JSON.stringify(state))
            })
        },
        methods:{
            loginMsg(){
                //此处线上需要去掉注释
                var iframe = this.$refs.loginReframe
                if(iframe)iframe.src = this.intervalUrl
            },
            getUserInfo(){
                // var token='uRrmrt1jXtCxQXjG5kkwgqgu8LRy97sdJ3T9e/wyGYXE5 K5lBZAnCp8eo9T0ECCa2f9fJ1f7feK1SN7yBu280KeFIM3qILgxcTHjRnGwO0='
                // var tokenType='Basic_User'
                var token = sessionStorage.getItem('essist_portal_hub_token')
                var tokenType = sessionStorage.getItem('essist_portal_hub_tokenType')
                if(!token||!tokenType)return
                var params = {}
                params.token = token
                params.tokenType = tokenType
                this.$parent.loading=true
                this.$axios.post(this.$URL.userInfoUrl,null, {params: params}).then(result=>{
                    this.$parent.loading=false
                    console.log('header',result)
                     if(result!=null){
                            var userInfo = result
                            this.userName = userInfo.name
                            this.avatar = userInfo.picture
                            bus.$emit('getMenuList',userInfo)
                            console.log('这里根据token取到的用户email值存起来，用于给子应用调用',userInfo.email)
                            var user={
                                email:userInfo.email
                            }
                            actions.setGlobalState({user:user});
                            actions.setGlobalState({token:token,tokenType:tokenType})
                        }
                }).catch(err=>{
                    this.$parent.loading=false
                    console.log(err);
                    //this.$message.error({message:err})
                })
            },
            Profile(){
                this.visible=false
                console.log('Profile')
            },
            loginOut(){
                this.visible=false
                sessionStorage.removeItem('essist_portal_hub_token')
                sessionStorage.removeItem('essist_portal_hub_tokenType')
                window.location.href=this.$URL.logoutUrl+'?state='+window.direct
                console.log('loginOut')
            }
            // 侧边栏折叠
            // collapseChage(){
            //     console.log(this.collapse);
            //     this.collapse = !this.collapse;
            //     bus.$emit('collapse', this.collapse);
            // },

        }
    }
</script>
<style scoped>
    .header {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 65px;
        font-size: 22px;
        color: #2A3542;
        border-bottom: 1px solid #dee2e6;
    }
    .header .logo{
        margin-left: 20px;
        float: left;
        line-height: 60px;
        font-size: 18px;
    }
    .header-right{
        float: right;
        padding-right: 50px;
    }
    .userInfo{
        display: flex;
        cursor: pointer;
        margin-top: 13px;
    }
    .userInfo .userName{
        font-size: 16px;
        color: rgba(0, 0, 0, .5);
        padding-top: 10px;
        padding-left: 5px;
    }
    .logoBox{
        float: left;
        position: relative;
        z-index: 199;
        width: 290px;
    }
    .logoImg{
        width: 290px;
        margin-left: -10px;
        margin-top: 20px;
    }
    .userDialog>>>.el-dialog{
        margin-top: 7vh!important;
        margin-right: 10vh;
    }
    .userDialog>>>.el-dialog__header{
        display: none;
    }
    .userDialog>>>.el-dialog__body{
        padding: 0;
    }
    .left{
        position: absolute;
        left: 5px;
        top:5px;
    }
    .right{
        position: absolute;
        right: 5px;
        top:5px;
    }
    .operate{
        margin-top: 20px;
        background: #f8f9fa;
        height: 50px;
        line-height: 50px;
        position: relative
    }
    /*.sidebar .el-menu{*/
    /*    background-color: #343a40!important;*/
    /*}*/

</style>
