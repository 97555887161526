import axios from 'axios'; // 引入axios
// import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据
// axios.defaults.baseURL = process.env.VUE_APP_Axios_baseURL;
// 请求超时时间
axios.defaults.timeout = 30000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
  config => {
    // if (config.method === 'post') {
    //   config.data = QS.stringify(config.data);
    // }
    return config;
  },
  error => {
    return Promise.error(error);
  })

// 响应拦截器
axios.interceptors.response.use(
  response => {
    console.log("response",response)
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  error => {
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 404:
          // alert("网络请求不存在")
          break;
        case 500:
          // alert("服务器内部错误")
          break;
        default:
          console.log('错误信息',error.response)
          // alert(error.response.data.message)
      }
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error);
    }
  }
);


export default axios
