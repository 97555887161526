<template>
  <div class="home" v-loading="loading" element-loading-text="Loading">
<!--    <img alt="Vue logo" src="../assets/logo.png">-->
<!--    <Menu/>-->
<!--    <router-view/>-->
      <Header></Header>
      <SideBar @routeChange="isRouterStateChange"></SideBar>
      <div class="content-box">
<!--          <Tags></Tags>-->
          <div class="tags-close-box">
              <el-dropdown @command="handleTags">
                  <el-button size="mini" type="primary">Actions
                      <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu size="small" slot="dropdown">
                      <el-dropdown-item command="refresh">Refresh tab</el-dropdown-item>
                      <el-dropdown-item command="close">Close tab</el-dropdown-item>
                      <el-dropdown-item command="all">Close all tabs</el-dropdown-item>
                      <el-dropdown-item command="other">Close other tabs</el-dropdown-item>
                  </el-dropdown-menu>
              </el-dropdown>
          </div>
          <div class="btn-fullscreen" @click="handleFullScreen">
              <el-tooltip effect="dark" :content="fullScreen" placement="bottom">
                  <i class="el-icon-rank" />
              </el-tooltip>
          </div>
          <div class="content">
              <el-tabs v-model="currentTab" type="card" closable @tab-remove="removeTab" @tab-click="handleClick">
                  <el-tab-pane
                          v-for="(item) in tabsList"
                          :key="item.id"
                          :label="item.name"
                          :name="item.id"
                  >
                      <div style="display:flex">
                          <el-breadcrumb separator-class="el-icon-arrow-right" style="padding: 10px 0px 20px 50px">
                          <el-breadcrumb-item v-for="(item1,index) in breadList" :key="index"
                                              :class="[index==breadList.length-1?'lastIndex':'','bread']"
                          ><span>{{item1}}</span>
                         
                          </el-breadcrumb-item>
                      </el-breadcrumb>
                      <i class="el-icon-full-screen"  
                            @click="switchFullScreen"
                            v-show="!isFullScreen" 
                            style="color:#008080;width: 16px; height: 16px;margin-left:10px;margin-top:10px;cursor:pointer"></i>
                      <i class="el-icon-bottom-left"
                            @click="switchFullScreen"
                            v-show="isFullScreen" 
                            style="color:#008080;width: 14px; height: 14px;margin-left:10px;margin-top:10px;cursor:pointer;
                            border:1px solid #008080;font-size:10px;display:flex;justify-content: flex-end;align-items: flex-start;"></i>
                       <!-- <el-image
                                @click="switchFullScreen"
                                v-show="!isFullScreen"
                              style="width: 16px; height: 16px;margin-left:10px;margin-top:10px;cursor:pointer"
                              :src="img_full"></el-image>
                              <el-image
                                @click="switchFullScreen"
                                v-show="isFullScreen"
                              style="width: 16px; height: 16px;margin-left:10px;margin-top:10px;cursor:pointer"
                              :src="img_normal"></el-image> -->
                      </div>
                      <div  v-loading="item.loading"  element-loading-text="Loading">
                      <iframe v-if="item.url.startsWith('http')" :ref="'iframe_'+item.id" @load="finished(item)" :class="{'insertHtmlfull':isFullScreen,'insertHtml':!isFullScreen}" :src="item.url" frameborder="0"></iframe>
                      </div>
                  </el-tab-pane>
<!--                  <keep-alive>-->
<!--                      <router-view style="height: 640px" v-show="isRouter"></router-view>-->
<!--                  </keep-alive>-->
                  <!--此处子应用作为微应用挂载-->
                <!-- <keep-alive> -->
                    <section id="frame" :class="{'frameHfull':isFullScreen,'frameH':!isFullScreen}" v-show="isRouter"></section>
                <!-- </keep-alive> -->
              </el-tabs>
              <div class="line"></div>
              <div class="copyRight">©2020 eAssist Inc. All Rights Reserved.</div>
          </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Menu from '@/components/Menu.vue'
import Header from "../components/commom/Header.vue";
import SideBar from "../components/commom/SideBar.vue";
// import Tags from "../components/commom/Tags.vue";
import bus from '../plugins/bus.js'
import startQiankun from '../micro'

export default {
  name: 'Home',
  components: {
      Header,SideBar
    // Menu
  },
    data(){
      return{
          isRouter:false,//当前tab页是否是路由页面
          tabsList:[],
          currentTab:'dashboard_000001',
          loading:false,
          fullscreen:false,
          breadList:[],
          isFullScreen:false,
      }
    },
    computed:{
        fullScreen(){
            if(this.fullscreen){
                return 'Normal';
            }else{
                return 'Full Screen';
            }
        },
    },
    methods:{
        switchFullScreen(){
            this.isFullScreen = !this.isFullScreen
            bus.$emit('changeScreen',this.isFullScreen)
        },
        isHttp (s) {
            return /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/.test(s)
        },
        isRouterStateChange(state){
            console.log('改变路由状态',state)
            this.isRouter=state
        },
        handleClick(tab){
            console.log('切换tab',tab)
            this.tabsList.forEach(item=>{
                if(tab.name==item.id){
                    this.breadList = item.path
                    console.log('切换了',item)
                    this.isRouter = !this.isHttp(item.url)
                    if(this.isRouter){
                        // var testUrl='/apps/itAdmin'
                        history.pushState(null, item.url, item.url)
                        // history.pushState(null, testUrl, testUrl)
                    }else{
                        history.pushState(null, '/', '/')
                    }
                    bus.$emit('ChangeTab',item)
                }
            })

            // console.log('切换tab',tab.name)
        },
        handleTags(command) {
            switch (command) {
                case "other":
                    this.closeOther()
                    break;
                case 'all':
                    this.closeAll();
                    break;
                case "refresh":
                    this.refreshTab()
                    break;
                case "close":
                    this.closeCurrent()
                    break;
            }
        },
        showLoading(item){
            var loadingMask =  document.getElementById('pane-'+item.id).getElementsByClassName('el-loading-mask')[0]
            if(loadingMask)
                loadingMask.style.display='block'
        },
        hideLoading(item){
            var loadingMask =  document.getElementById('pane-'+item.id).getElementsByClassName('el-loading-mask')[0]
            if(loadingMask)
                loadingMask.style.display='none'
        },
        finished(item){//iframe加载完会调用此方法
            // var iframe = this.$refs['iframe_'+item.id]
            // this.tabsList.forEach(d=>{
            //     // var allFrame = this.$refs['iframe_'+d.id]
            //     console.log('所有item的加载状况',d)
            //     if(item.id==d.id)item.loading=false
            // })
            this.hideLoading(item)
            console.log('加载完成',item)
            // item.loading=false
        },
        closeOther(){
            var homeTab=JSON.parse(JSON.stringify( this.tabsList[0]))
            this.tabsList.forEach((item)=>{
                if(item.id!=homeTab.id&&item.id!=this.currentTab){
                    this.removeTab(item.id)
                }
            })
        },
        closeAll(){
            var homeTab = JSON.parse(JSON.stringify(this.tabsList[0]))
            this.tabsList= []
            this.tabsList.push(homeTab)
            this.currentTab=homeTab.id
            this.breadList = homeTab.path
        },
        refreshTab(){
            console.log('currentTab',this.currentTab)
            this.tabsList.forEach((item,index)=>{
                if(item.id==this.currentTab){
                    console.log('当前tab',item)
                    if(this.isRouter){
                        history.pushState('','/','/')
                        setTimeout(()=>{
                             history.pushState('',item.url,item.url,)
                        },200)
                        console.log('shuaxin',item)
                    }else{
                        var iframe = this.$refs['iframe_'+this.currentTab][0]
                        console.log('iframe',iframe)
                        item.loading=true
                        this.showLoading(item)
                        iframe.src=item.url
                    }
                }
            })
        },
        closeCurrent(){
            console.log('当前激活tab',this.currentTab)
            if(this.currentTab==this.tabsList[0].id)return
            this.removeTab(this.currentTab)
            
        },
        handleFullScreen(){
            let element = document.documentElement;
            if (this.fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            } else {
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.webkitRequestFullScreen) {
                    element.webkitRequestFullScreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.msRequestFullscreen) {
                    // IE11
                    element.msRequestFullscreen();
                }
            }
            this.fullscreen = !this.fullscreen;
        },
        //标签页移除
        removeTab(targetName) {
            if(targetName==this.tabsList[0].id)return
            console.log('移除',targetName)
            let tabs = this.tabsList;
            let activeName = this.currentTab;
            if (activeName === targetName) {
                tabs.forEach((tab, index) => {
                    if (tab.id === targetName) {
                        let nextTab = tabs[index + 1] || tabs[index - 1];
                        console.log('删除后下一个变迁是',nextTab)
                        if (nextTab) {
                            activeName = nextTab.id;
                            this.currentTab = activeName;
                            bus.$emit('ChangeTab',{id:activeName})
                            this.isRouter = !nextTab.url.startsWith('http')
                            if(!this.isRouter) history.pushState(null, '/', '/')
                            else history.pushState(null, nextTab.url, nextTab.url)
                            this.breadList = nextTab.path
                        }
                    }
                });
            }
            this.currentTab = activeName;
            bus.$emit('ChangeTab',{id:activeName})
            this.tabsList = tabs.filter(tab => tab.id !== targetName);
        },
    },
    created() {
        var homeTab = {}
        homeTab.name='Dashboard'
        homeTab.url=this.$URL.dashboardUrl
        homeTab.id='dashboard_000001'
        homeTab.path=['Home','Dashboard']
        homeTab.loading=true
        this.breadList = homeTab.path
        this.tabsList.push(homeTab)
        bus.$on('Jump', target => {
            console.log('收到跳转请求'+JSON.stringify(target));
            //点击menu跳转，如果当前tabs存在则不需要添加tab
            var jumpItem = target
            var repeat = this.tabsList.some(item=>item.id==jumpItem.id)
            if(!repeat){
                if(target.url.indexOf('http')<0)target.loading=false
                else target.loading=true
                console.log('标签信息是',target)
                this.tabsList.push(target)
            }
            this.currentTab=jumpItem.id
            this.breadList = jumpItem.path
        })
    },
    mounted() {
      if(!window.startQiankun){
          window.startQiankun=true
          startQiankun()
      }
    }
}
</script>
<style scoped>
    .content >>>.el-tabs__header{
        width: calc(100% - 160px);
    }
    .tags-close-box {
        position: absolute;
        right: 40px;
        top: 70px;
        box-sizing: border-box;
        padding-top: 1px;
        text-align: center;
        width: 110px;
        height: 30px;
        background: #fff;
        /*box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);*/
        z-index: 10;
    }
    .btn-fullscreen{
        /*transform: rotate(45deg);*/
        right: 10px;
        top:70px;
        font-size: 24px;
        position: absolute;
        z-index: 10;
    }
    .content-box{
        background: #fff;
    }
    .lastIndex>>>.el-breadcrumb__inner{
        color: #fbae00!important;
        font-weight: bold!important;
    }
    /*.bread>>>.el-breadcrumb__inner{*/
    /*    cursor: pointer!important;*/
    /*}*/
    /**大分辨率比如1920x1080走默认配置*/
    .insertHtml{
        background:#f8f9fa;
        width: 100%;
        height: calc(100vh - 200px)
    }
    .frameH{
        height: calc(100vh - 200px);
        overflow-y: scroll
    }

    .insertHtmlfull{
        background:#f8f9fa;
        width: 100%;
         height: calc(100vh - 130px)
    }
    .frameHfull{
        height: calc(100vh - 130px);
        overflow-y: scroll
    }
    .line{
        height: 1px;
        width: 100%;
        background: #dee2e6;
        margin-top: -4px
    }
    .copyRight{
        width: 100%;
        text-align: right;
        font-size:13px;
        margin-top: 10px;
        padding-right: 10px
    }

    
</style>
